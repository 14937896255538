export default class LncWebMultiSelect {
  constructor (name) {
    this.element = document.querySelector(name);
    this.element?.addEventListener('instance', (event) => {this.ref = event.detail;});
  }

  isOptionsPartiallySelected(){
    return this.ref.isOptionsPartiallySelected();
  }

  isAllOptionsSelected(){
    return this.ref.isAllOptionsSelected();
  }

  setErrorMessage(errorMessage){
    this.ref.errorMessage=errorMessage;
  }

  empty(){
    this.ref.writeValue([]);
  }

  removeError(errorMessage){
    this.ref.errorMessage=undefined;
  }

  getSelectedValues(){
    return this.ref.selectedValues;
  }
}
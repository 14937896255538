import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api client mes-taches.
 */
export default class MesTachesApiClientService extends LncBaseApiClientService {
  /**
   * Action pour récupérer la liste des tâches de l'utilisateur connecté.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<TacheDto[]>} La promesse d'une réponse.
   */
  getListeTaches(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/taches?idContext=PB2B")
        .then((response) => {
          if (response.status === 204) {
            resolve([]);
          } else {
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Lancer le téléchargement d'un tâche
   * @param cnAlex {string} L'identifiant alex de la personne
   * @param idTache {string} L'identifiant de la tache
   * @returns {Promise<void|*>} La promesse d'une réponse
   */
  telechargerTache(cnAlex, idTache) {
    return this.fetchFile("/api/private/v1/personnes/" + cnAlex + "/taches/" + idTache + "/fichier");
  }

  /**
   * Lancer le téléchargement d'un fichier avec la méthode GET
   * @param path {string} Le chemin vers le service à appeler,
   * le chemin doit commencer par une barre oblique.
   * @returns {Promise<void|*>}
   */
  fetchFile(path) {
    const FETCH_CONFIG = {
      method: "GET",
      cache: "no-store",
      credentials: "include",
      mode: "cors",
    };

    let fileName = null;

    return fetch(this.baseUrl + path, { ...FETCH_CONFIG })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        fileName = this.extractFileNameFromHeaders(response.headers);
        return response.blob();
      })
      .then((blob) => {
        if (fileName) {
          const url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = fileName;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          const file = window.URL.createObjectURL(blob);
          window.location.assign(file);
        }
        return Promise.resolve();
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * Récupère le nom du fichier csv à partir du header content-disposition
   *
   * @param headers {Headers} Les headers de la requête http
   * @returns {string|null} Le nom du fichier
   */
  extractFileNameFromHeaders(headers) {
    const contentDisposition = headers.get("content-disposition");
    if (!contentDisposition) {
      return null;
    }

    const fileNameMatches = contentDisposition.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i);
    if (!fileNameMatches) {
      return null;
    }

    return fileNameMatches[1];
  }

  /**
   * Modifier une tache
   * @param cnAlex {string} L'identifiant alex de la personne
   * @param tache {TacheDto} La tache à modifier
   * @returns {Promise<TacheDto>} La promesse d'une réponse
   */
  modifierTache(cnAlex, tache) {
    return new Promise((resolve, reject) => {
      this.put("/api/private/v1/personnes/" + cnAlex + "/taches/" + tache.id, tache)
        .then((response) => {
          resolve(response.content);
        })
        .catch(() => {
          reject();
        });
    });
  }
}

import {
  LNC_ESPACE,
  LncMonCompteClientService,
} from "@odw/lnc-services/js-services/api-client/lnc-mon-compte-client.service";
import { neufsneufSettings } from "../services/settings-loader";
import MesEntreprisesService from "./mes-entreprises.service";
import MesTachesApiClientService from "./mes-taches.api.client.service";
import MesPrmsEntrepriseService from './mes-prms-entreprise.service'

const mesEntreprisesService = new MesEntreprisesService(neufsneufSettings.url.mes_entreprises_api_url);
const monCompteClientService = new LncMonCompteClientService(
  neufsneufSettings.url.mon_compte_client_api_url,
  LNC_ESPACE.ENTREPRISE
);
const mesTachesService = new MesTachesApiClientService(neufsneufSettings.url.mes_taches_api_url);
const mesPrmsEntrepriseService = new MesPrmsEntrepriseService(neufsneufSettings.url.mes_prms_entreprise_api_url);

export { mesEntreprisesService, mesTachesService, monCompteClientService, mesPrmsEntrepriseService };

/**
 * Les types d'environnement du portail entreprises
 */
const NeufsneufProfiles = {
  DEV: "dev",
  PROD: "prod",
};

/**
 * Les modals du portail entreprises
 */
const ModalTypes = {
  LOGOUT: "#modal-deconnexion",
  EXTERNAL_LINK: "#modal-external-link",
};

/**
 * Évènements du store
 */
const StoreKeys = {
  INFO_COMPTE: "infoCompteDto",
  HABILITATIONS: "habilitations",
  ETAT_DEMANDES: "etatDemandes",
  SELECTED_SIREN: "selectedSiren",
  SELECTED_SIREN_MODEL: "selectedSirenModel",
  SELECTED_PRMS: "selectedPrms",
  TACHES_TEMP: "tachesTemp",
  TACHES: "taches",
};

const TypeParcours = {
  BILAN_CONTINUITE: "bilans",
  FEUILLET_GESTION: "feuillets-gestion",
  CONTRATS: "contrats",
};

const ColorEcoWatt = {
  VERT: "VERT",
  ROUGE: "ROUGE",
  ORANGE: "ORANGE",
  GRIS: "GRIS",
};

/**
 * Les modals ecowatt
 */
const ModalTypesEcoWattFeatureFlipping = {
  ROUGE_ORANGE_FEATURE_FLIPPING_OFF: "modal-ecowatt-rouge-orange-feature-flipping-off",
  ROUGE_ORANGE_FEATURE_FLIPPING_ON: "modal-ecowatt-rouge-orange-feature-flipping-on",
};

const MessageEcoWatt = {
  VERT: "Signal vert",
  ROUGE: "Système électrique très tendu",
  ORANGE: "Système électrique tendu",
  GRIS: "Prévisions à venir à 17h30 (12h30 le vendredi)",
};

const MessageDayInfo = {
  DAY_0_HOUR: "8h00",
  DAY_1_HOUR: "20h00",
  DAT_2_HOUR: "17h00",
  DAY_3_HOUR: "17h00",
  INFO_DAY_0: "Veuillez noter que le plan de délestage a été mis à jour vers ",
  INFO_DAY_1_2: "Veuillez noter que le plan de délestage sera mis à jour vers ",
  INFO_DAY_3: "Veuillez noter qu’un plan de délestage sera mis à votre disposition vers ",
  DESCRIPTION: "Vous pourrez accéder uniquement aux données des compteurs de votre périmètre titulaire.",
  MSG_ATTENTION: "Attention :  un téléchargement peut produire un fichier vide si aucun de vos compteurs n’est impacté."
}


export { NeufsneufProfiles, ModalTypes, StoreKeys, TypeParcours, ColorEcoWatt, MessageEcoWatt, MessageDayInfo, ModalTypesEcoWattFeatureFlipping };

import { NeufsneufModal } from '../../js/components/common/neufsneuf-modal'
import { multiSelectModalEcowattDemandePrms } from './main'
import { TagCommanderManager } from '../../js/services/tag-commander-manager'
import { RECHERCHER_COMPTEURS_POPIN_CONFIRMATION_DEMANDE } from '../../js/tag-plan'

export default class ModalConfirmShowTasks extends NeufsneufModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor () {
    super("modal-confirm-show-tasks");
    this._initModal();
  }

  _initModal(){
    document.getElementById("modal-confirm-show-tasks").getElementsByClassName("close-button").item(0).addEventListener('click', () => this.hideDismiss());
    document.getElementById("modal-confirm-show-tasks").getElementsByClassName("show-tasks-button").item(0).addEventListener('click', () => this._showTachesPrmsDelestes());
    const handleModalActions = () => {
      multiSelectModalEcowattDemandePrms.removeError();
      const toastError = document.getElementById("modal-ecowatt-rouge-orange-feature-flipping-on").querySelector("neufsneuf-toast");
      toastError.hide();
    };
    this.onAccept(handleModalActions);
    this.onDismiss(handleModalActions);
  }

  /**
   * Affichage de la liste des taches des prms delestes
   */
  _showTachesPrmsDelestes () {
    // Sélectionner le bouton de téléchargement dans le DOM
    this.hideSuccess();
    multiSelectModalEcowattDemandePrms.removeError();
    const buttonDemandeTelechargement = document.querySelector('lnc-web-header-entreprise').querySelector('lnc-task').querySelector('lnc-header-button').querySelector('button')
    buttonDemandeTelechargement.click()
  }

  /**
   * Ouverture de la modal ecowatt
   */
  show() {
    super.show();
    TagCommanderManager.tagPage(RECHERCHER_COMPTEURS_POPIN_CONFIRMATION_DEMANDE);
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement accept
   */
  hideSuccess() {
    super.hideOnAccept();
  }

  /**
   * Fermeture de la modal ecowatt avec émission d'un événement dismiss
   */
  hideDismiss() {
    super.hideOnDismiss();
  }
}
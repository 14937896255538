import { NeufsneufModal } from "../../js/components/common/neufsneuf-modal";
import { TagCommanderManager } from "../../js/services/tag-commander-manager";

export default class ModalEcowattVert extends NeufsneufModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalTBD}.
   */
  constructor() {
    super("modal-ecowatt-vert");

    this.onAccept(() => {
      TagCommanderManager.tagClick(FERMETURE_MODAL_CONDITIONS_GENERALES);
    });
  }

  show() {
    super.show();
  }
}

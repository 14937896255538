import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

/**
 * Service pour réaliser les appels à l'api entreprise client.
 */
export default class MesPrmsEntrepriseService extends LncBaseApiClientService {

  /**
   * Action pour exporter les prms delestes de l'utilisateur
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @param listSirens {void|*} Liste des SIRENs associée aux prms à exporter
   * @returns {Promise<TacheDto[]>} La promesse d'une réponse.
   */
  exportPrmsDelestes(cnAlex, listSirens, dateFormated) {
    return new Promise((resolve, reject) => {
      this.post("/api/private/v1/personnes/" + cnAlex + "/demande-prm-delestes", {
        sirens: listSirens, // liste des sirens (doit être un tableau de chaînes de 9 chiffres)
        dateDemande: dateFormated, // date en format string
        idContext: 'PB2B' //context id pour B2B
      }).then((response) => {
        if (response.status === 204) {
          resolve([])
        } else {
          resolve(response.content);
        }
      }).catch(() => {
        reject();
      });
    });
  }
}

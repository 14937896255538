import { ComponentHelper } from "./component-helper";
import { CookieMicroapp } from "./cookie-microapp";
import { CookiePortail } from "./cookie-portail";
import { StoreKeys } from "./enums";
import { PortailLocalStorage } from "./portail-local-storage";

/**
 * Store pour le portail entreprises
 * @constructor
 * @public
 */
class NeufsneufStoreManager {
  /**
   * Initialise une instance de type {@type NeufsneufStoreManager}
   */
  constructor() {
    /**
     * Les abonnées
     * @type {{}}
     * @private
     */
    this.subscribers = {};

    /**
     * Les données du store
     * @type {NeufsneufStoreData}
     * @private
     */
    this.store = {};
  }

  /**
   * Get l'identifiant alex de la personne connectée
   * @return {string} L'identifiant alex de la personne connectée
   */
  get cnAlex() {
    return this.store.cnAlex;
  }

  /**
   * Get les informations de l'utilisateur
   * @return {InfoCompteDto} Les informations de l'utilisateur
   */
  get infoCompte() {
    return this.store.infoCompteDto;
  }

  /**
   * Set les informations de l'utilisateur
   * @param {InfoCompteDto} value Les informations de l'utilisateur
   */
  set infoCompte(value) {
    this.store.infoCompteDto = value;
    this._publish(StoreKeys.INFO_COMPTE, value);
  }

  /**
   * Get la date sélectionnée formatée dans le bandeau météo.
   * @return {string|null} La date sélectionnée dans le bandeau météo.
   */
  get bandeauMeteoDateSelectionneeFormatee() {
    return CookiePortail.bandeauMeteoDateSelectionneeFormatee;
  }

  /**
   * Set la date sélectionnée formatée dans le bandeau météo.
   * @param {string} value La date sélectionnée dans le bandeau météo.
   */
  set bandeauMeteoDateSelectionneeFormatee(value) {
    this.store.bandeauMeteoDateSelectionneeFormatee = value;
    CookiePortail.bandeauMeteoDateSelectionneeFormatee = value.toString();
  }

  /**
   * Get le siren titulaire selectionné
   * @return {string} Le siren selectionné (sirenTitulaire)
   */
  get selectedSiren() {
    return this.store.selectedSiren;
  }

  /**
   * Get le siren model selectionné
   * @return {SirenModel} Le siren model selectionné
   */
  get selectedSirenModel() {
    return this.store.selectedSirenModel;
  }

  /**
   * Récupère-les prms sélectionnées
   * @returns {string[]} Les prms sélectionnées
   */
  get selectedPrms() {
    return this.store.selectedPrms;
  }

  /***
   * Action pour envoyer un message pym aux microapps lors du changement de siren
   * Ajout du siren dans le CookieManager
   * Set le siren selectionné
   * @param {string} value Le siren model selectionné {@link SirenModel} au format JSON
   */
  set selectedSiren(value) {
    if (this.store.selectedSirenModel === value) {
      return;
    }
    /** @type SirenModel */
    const sirenModel = JSON.parse(value);
    this.store.selectedSiren = sirenModel.sirenTitulaire;
    this.store.selectedSirenModel = sirenModel;
    CookieMicroapp.selectedSiren = sirenModel.sirenTitulaire;
    CookieMicroapp.selectedSirenModel = value;
    ComponentHelper.pymSendMessage("actionChangementSiren", value);
    this._publish(StoreKeys.SELECTED_SIREN, sirenModel);
  }

  /***
   * Ajoute-les prms dans le CookieManager et le StoreManager
   * @param {string[]} value Les prms sélectionnés
   */
  set selectedPrms(value) {
    if (this.store.selectedPrms === value) {
      return;
    }
    this.store.selectedPrms = value;
    CookieMicroapp.selectedPrms = value;
    this._publish(StoreKeys.SELECTED_PRMS, value);
  }

  /**
   * Get le périmètre de l'utilisateur
   * @return {HabilitationDetailleeDto[]} Le périmètre de l'utilisateur
   */
  get habilitations() {
    return this.store.habilitations;
  }

  /**
   * Set la liste des habilitations de l'utilisateur.
   * @param {HabilitationDetailleeDto[]} value La liste des habilitations de l'utilisateur.
   */
  set habilitations(value) {
    this.store.habilitations = value;
    this._publish(StoreKeys.HABILITATIONS, value);
  }

  /**
   * get l'état des demandes de rattachement aux entreprises
   * @return {EtatDemandes}
   */
  get etatDemandes() {
    return this.store.etatDemandes;
  }

  /**
   * set l'état des demandes de rattachement aux entreprises
   * @param {EtatDemandes} value
   */
  set etatDemandes(value) {
    this.store.etatDemandes = value;
    this._publish(StoreKeys.NB_ETAT_DEMANDES, this.store.etatDemandes);
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {boolean} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   *
   */
  get externalLinkAskLater() {
    return this.store.externalLinkAskLater;
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {boolean} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.store.externalLinkAskLater = value;
    CookiePortail.externalLinkAskLater = value.toString();
  }

  /**
   * Get les taches temporaires de l'utilisateur
   * @return {TacheDto[]} La liste des taches temporaires de l'utilisateur
   */
  get tachesTemp() {
    return this.store.tachesTemp;
  }

  /**
   * Set les taches temporaires de l'utilisateur
   * @param {TacheDto[]} value La liste des taches temporaires de l'utilisateur
   */
  set tachesTemp(value) {
    this.store.tachesTemp = value;
    PortailLocalStorage.taches = value;
    this._publish(StoreKeys.TACHES_TEMP, value);
  }

  /**
   * Get les taches de l'utilisateur
   * @return {TacheGroupDto} Les taches de l'utilisateur
   */
  get taches() {
    return this.store.taches;
  }

  /**
   * Set les taches de l'utilisateur
   * @param {TacheGroupDto} value Les taches de l'utilisateur
   */
  set taches(value) {
    this.store.taches = value;
    this._publish(StoreKeys.TACHES, value);
  }

  /**
   * get l'état des CGU utilisateur.
   * @return {boolean} true si les CGU sont validés par l'utilisateur. false sinon.
   */
  get isCguValid() {
    return this.store.isCguValid;
  }

  /**
   * Initialise les données du store
   * @public
   * @param {UserInstance} userInstance Les informations de l'utilisateur
   */
  initialize(userInstance) {
    // Configuration du cookie et du local storage
    if (CookiePortail.interneId !== userInstance.cnAlex) {
      CookiePortail.reset();
      CookieMicroapp.reset();
      PortailLocalStorage.reset();
    }
    CookiePortail.interneId = userInstance.cnAlex;

    // Initialiser les donnes dans le store
    this.store = { ...userInstance };
    this.store.tachesTemp = PortailLocalStorage.taches ? PortailLocalStorage.taches : [];
    this.store.taches = { statutOk: true, liste: [] };

    // Notifier les abonnés après avoir initialiser le store
    this._publish(StoreKeys.INFO_COMPTE, this.store.infoCompteDto);
    this._publish(StoreKeys.HABILITATIONS, this.store.habilitations);
    this._publish(StoreKeys.NB_ETAT_DEMANDES, this.store.etatDemandes);
    this._publish(StoreKeys.TACHES_TEMP, this.store.tachesTemp);
  }

  /**
   * Souscription aux events du store
   * @public
   * @param {StoreKeys} channel Évènement auquel s'abonner
   * @param {*} sub Callback à déclencher
   */
  subscribe(channel, sub) {
    this.subscribers[channel] = this.subscribers[channel] || [];
    this.subscribers[channel].push(sub);
  }

  /**
   * Action pour vérifier si l'utilisateur avait accédé au portail par le passé.
   * @return {boolean} Une valeur indiquant si l'utilisateur avait accédé au portail par le passé
   */
  wasConnected() {
    const cnAlex = CookiePortail.interneId;
    return typeof cnAlex !== "undefined" && cnAlex !== "null";
  }

  /**
   * Action pour vider le store et les informations persistantes pour l'utilisateur.
   */
  delete() {
    this.store = {};
    CookiePortail.delete();
    CookieMicroapp.delete();
    PortailLocalStorage.delete();
  }

  /**
   * Publication des events du store
   * @private
   * @param {StoreKeys} channel Événement publié
   * @param {*} args Paramètres associés à l'évènement
   */
  _publish(channel, ...args) {
    (this.subscribers[channel] || []).forEach((sub) => sub(...args));
  }
}

const StoreManager = new NeufsneufStoreManager();
export { StoreManager };

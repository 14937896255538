import { NeufsneufModal } from "../common/neufsneuf-modal";

/**
 * La modal de confirmation d'export et de consultation des tâches en cours
 */
export default class ModalSearchExportSuccess extends NeufsneufModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalSearchExportSuccess}.
   */
  constructor() {
    super("modal-search-export-success");

    /**
     * Action pour réagir au clic "Consulter vos tâches".
     * @override
     */
    this.onAccept(() => {
      //TODO tagCommander ?
      // Ouvre la liste des tâches au clic sur le bouton de la popin
      document.querySelector("lnc-web-header-entreprise lnc-task lnc-header-button")?.click();
    });
  }
}

/**
 * Composant de base pour créer des modals
 */
export class NeufsneufModal {
  /**
   * Initialise une instance de type {@type NeufsneufModal}
   * @param {string} id id HTML de la modal
   */
  constructor(id) {
    /**
     * Référence vers l'élément \<lnc-web-modal\>
     */
    this.element = document.getElementById(id);

    this.element?.addEventListener("instance", (event) => {
      this.modalRef = event.detail;
    });
  }

  /**
   * Définit la callback appelée lors de la fermeture de la modal (clic sur la croix de fermeture ou leftButton)
   * @param {() => {}} callback appelée lors de l'événement dismiss
   */
  onDismiss(callback) {
    this.element?.addEventListener("dismiss", callback.bind(this));
  }

  /**
   * Définit la callback appelée lors de la fermeture de la modal (clic sur rightButton)
   * @param {() => {}} callback appelée lors de l'événement accept
   */
  onAccept(callback) {
    this.element?.addEventListener("accept", callback.bind(this));
  }

  /**
   * Affiche la modal
   */
  show() {
    this.modalRef?.show();
  }


  /**
   * Cacher la modal avec émission d'un événement dismiss
   */
  hideOnDismiss(){
    this.modalRef?.hideOnDismiss();
  }

  /**
   * Fermeture de la modal avec émission d'un événement accept
   */
  hideOnAccept() {
    this.modalRef?.hideOnAccept();
  }
}

import { mesEntreprisesService, monCompteClientService } from "../api-client/index";
import { PageManager } from "./page-manager";
import { HeaderMenuData, PageTypes, footerData, neufsneufSettings } from "./settings-loader";
import { StoreManager } from "./store-manager";
import { TagCommanderManager } from "./tag-commander-manager";

/**
 * Service pour controller l'utilisateur et son parcours sur le portail entreprises.
 */
class NeufsneufParcoursController {
  /**
   * Initialise une instance de type {@type NeufsneufParcoursController}.
   */
  constructor() {}

  /**
   * Action pour charger les informations de l'utilisateur.
   * En cas d'erreur la promesse n'est pas tenue et l'utilisateur est redirigé :
   *  - url de vie des services ko => redirection page de maintenance
   *  - info personne connectée pas disponible => redirection page de connexion
   *  - info du compte indisponible => redirection page service indisponible
   * @returns {Promise<UserInstance>} la promesse d'une réponse avec les informations de la personne connectée.
   */
  loadUserInstance() {
    const alivePromises = [monCompteClientService.isAlive(), mesEntreprisesService.isAlive()];

    return new Promise((resolve, reject) => {
      Promise.all(alivePromises)
        .then(() => {
          monCompteClientService
            .getUserInfosV2()
            .then((userInfos) => {
              const isCguValid =
                userInfos?.dateCGU && userInfos.dateCGU.getTime() >= neufsneufSettings.last_cgu_update.getTime();

              if (!userInfos.compteFinalise) {
                resolve({
                  cnAlex: userInfos.cnAlex,
                  infoCompteDto: userInfos,
                  habilitations: [],
                  etatDemandes: { validees: 0, enCours: 0, refusees: 0 },
                  isCguValid: isCguValid,
                });
              } else {
                const promisesArray = [
                  mesEntreprisesService.getHabilitation(userInfos.cnAlex),
                  mesEntreprisesService.getEtatDemandes(userInfos.cnAlex),
                ];

                Promise.allSettled(promisesArray)
                  .then((values) => {
                    const habilitations = values[0],
                      etatDemandes = values[1];
                    /*
                     * On redirige vers la page d'erreur dans les cas suivants:
                     * - Si le compte est finalisé mais on n'a pas récupéré la liste des habilitations (demandes validées).
                     *   OU
                     * - Si le compte est finalisé et on n'a pas d'habilitations (=== 0) mais on n'a pas récupéré le nb de demandes en cours.
                     */
                    if (
                      habilitations?.status !== "fulfilled" ||
                      (habilitations?.value?.length === 0 && etatDemandes.status !== "fulfilled")
                    ) {
                      TagCommanderManager.tagPage({
                        page: "service_indisponible",
                      });

                      PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
                      reject("service indisponible");
                      return; // exit reject
                    }
                    resolve({
                      cnAlex: userInfos.cnAlex,
                      infoCompteDto: userInfos,

                      habilitations: habilitations.value,
                      etatDemandes: {
                        validees: habilitations.value?.length,
                        enCours: etatDemandes.status === "fulfilled" ? etatDemandes.value.enCours : 0,
                        refusees: etatDemandes.status === "fulfilled" ? etatDemandes.value.refusees : 0,
                      },
                      isCguValid: isCguValid,
                    });
                  })
                  .catch(() => {
                    TagCommanderManager.tagPage({
                      page: "service_indisponible",
                    });
                    PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
                    reject("service indisponible");
                  });
              }
            })
            .catch((error) => {
              // Cas mode Ghost sur un espace sur lequel il n'est pas autorisé
              if (error.status === 403) {
                PageManager.redirectTo(PageTypes.SERVICE_INDISPONIBLE);
                reject("non autorisé sur l'espace");
                // Autre cas d'erreur
              } else {
                // Si on avait un utilisateur connecté... "Déconnexion forcée"
                if (StoreManager.wasConnected()) {
                  TagCommanderManager.tagPage({
                    page: "deconnexion_involontaire",
                  });
                }
                StoreManager.delete();
                window.location.replace(
                  neufsneufSettings.envs.base_url_vm + "/authenticate?target=" + window.location.href
                );
                reject("info personne connectée indisponible");
              }
            });
        })
        .catch(() => {
          TagCommanderManager.tagPage({
            page: "maintenance",
          });
          PageManager.redirectTo(PageTypes.MAINTENANCE);
          reject("service indisponible");
        });
    });
  }

  /**
   * Action pour renvoyer l'utilisateur sur le parcours en fonction des valeurs de {@param userInstance}.
   * @param userInstance {UserInstance} Les informations de la personne connectée.
   * @returns {boolean} Une valeur indiquant si la redirection a été enclenchée.
   */
  redirectionParcours(userInstance) {
    if (this._isPageAlwaysAccessible()) {
      return false;
    }

    if (!userInstance.isCguValid) {
      return PageManager.redirectTo(PageTypes.CGU);
    }

    // Dans le cas de la gestion de compte, on vérifie uniquement les CGU, si ok on peut accéder à la page.
    if (PageManager.current.path === HeaderMenuData.GESTION_COMPTE.url) {
      return false;
    }

    if (!userInstance.infoCompteDto.compteFinalise) {
      return PageManager.redirectTo(PageTypes.GESTION_PROFIL);
    }

    // pour la page "associer-entreprise", on vérifie si les CGU sont validés et que le compte est finalisé, si ok on peut accéder à la page.
    if (PageManager.current.path === PageTypes.ASSOCIER_ENTREPRISE.path) {
      return false;
    }

    if (
      (userInstance.etatDemandes.enCours > 0 || userInstance.etatDemandes.refusees > 0) &&
      userInstance.etatDemandes.validees === 0
    ) {
      return PageManager.redirectTo(PageTypes.VOS_ENTREPRISES);
    }

    if (
      userInstance.etatDemandes.enCours === 0 &&
      userInstance.etatDemandes.refusees === 0 &&
      userInstance.etatDemandes.validees === 0
    ) {
      return PageManager.redirectTo(PageTypes.ASSOCIER_ENTREPRISE);
    }

    return false;
  }

  /**
   * Retourne true si la page courante fait partie des pages toujours accessibles (auxquelles on applique pas la redirection forcées).
   *
   * La liste des pages toujours accessibles:
   * - Liens du footer.
   * - les liens "Assistance conseiller, Changer de profil et Déconnexion" du menu User.
   *
   * @returns {boolean}
   * @private
   */
  _isPageAlwaysAccessible() {
    // On récupère les pages du footer.
    const listUrlsFooter = footerData.flatMap((elem) => {
      return elem.data.flatMap((level2) => level2.url);
    });

    // On récupère les pages du menu user.
    const listUrlsMenuUserAlwaysAccessible = [HeaderMenuData.CHANGER_PROFIL.url, HeaderMenuData.SUPPORT_AGENT.url];
    const listUrlsAlwaysAccessible = listUrlsFooter.concat(listUrlsMenuUserAlwaysAccessible);

    return listUrlsAlwaysAccessible.includes(PageManager.current.path);
  }
}

const ParcoursController = new NeufsneufParcoursController(neufsneufSettings.envs.base_url_vm);
export { ParcoursController };

import { CookiePortail } from "../../js/services/cookie-portail";
import { ColorEcoWatt, MessageEcoWatt, ModalTypesEcoWattFeatureFlipping, MessageDayInfo, StoreKeys } from '../../js/services/enums'
import { StoreManager } from "../../js/services/store-manager";
import ModalPremiereConnexion from "./modal-premiere-connexion";
import { onClickParcours, startParcours } from "./parcours-guide";

import { mesEntreprisesService } from "../../js/api-client/index";
import { neufsneufSettings } from "../../js/services/settings-loader";

import { ComponentHelper } from "../../js/services/component-helper";
import { PageManager } from "../../js/services/page-manager";
import { PageTypes } from "../../js/services/settings-loader";
import { TagCommanderManager } from "../../js/services/tag-commander-manager";
import {
  CHARGEMENT_POPIN_PREMIERE_CONNEXION,
  CLICK_ACCEDER_TUTORIELS,
  CLICK_ACCEDER_VOS_COMPTEURS,
  CLICK_ACCEDER_VOS_MESURES,
  CLICK_AIDE_ET_CONTACT,
  CLICK_POPIN_PREMIERE_CONNEXION_LANCER_PARCOURS_GUIDE,
  CLICK_POPIN_PREMIERE_CONNEXION_PAS_MAINTENANT,
  PARCOURS_GUIDE,
  PASSAGE_DES_HIVERS_TELECHARGER_LES_COMPTEURS_IMPACTES,
  PASSAGE_DES_HIVERS_TOUT_COMPRENDRE_SUR_LES_SIGNAUX,
  PASSAGE_DES_HIVERS_TUILE,
} from "../../js/tag-plan";
import ModalEcowattVert from "./modal-ecowatt-vert";
import ModalEcowattOrangeRouge from './modal-ecowatt-rouge-orange'
import LncWebMultiSelect from './lnc-web-multi-select'
import ModalConfirmShowTasks from './modal-confirm-show-tasks'

require("./parcours-guide");
require("../require");
require("./page.scss");


// #region Configuration modal téléchargement des compteurs dans le bandeau meteo ecowatt
export const modalEcowattVert = new ModalEcowattVert()
export const multiSelectModalEcowattDemandePrms = new LncWebMultiSelect("#siren-lnc-multi-select-demande-prms")
export const modalEcowattRougeOrangeFeatureFlippingOff = new ModalEcowattOrangeRouge(ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_OFF)
export const modalEcowattRougeOrangeFeatureFlippingOn = new ModalEcowattOrangeRouge(ModalTypesEcoWattFeatureFlipping.ROUGE_ORANGE_FEATURE_FLIPPING_ON);
export const modalConfirmShowTasks = new ModalConfirmShowTasks();
const isFeatureFlippingEcowattOn = neufsneufSettings.feature_flipping.feature_flipping_telechargement_prms_delestes;
const isFeatureFlippingAfficherBandeauMeteo = neufsneufSettings.feature_flipping.feature_flipping_afficher_bandeau_meteo;

window.addEventListener("load", () => {
  if (isFeatureFlippingAfficherBandeauMeteo) {
    getInfoDaysEcowatt();
    document.getElementById("telecharger-compteurs-desktop").hidden = false;
    document.getElementById("telecharger-compteurs-mobile").hidden = false;
    document.getElementById("telecharger-compteurs-desktop").addEventListener("click", popinTelechargementCompteur);
    document.getElementById("telecharger-compteurs-mobile").addEventListener("click", popinTelechargementCompteur);
  }
});

function popinTelechargementCompteur() {
  if (document.querySelector("lnc-card-meteo > .active").classList.contains("vert")) {
    TagCommanderManager.tagClick(PASSAGE_DES_HIVERS_TELECHARGER_LES_COMPTEURS_IMPACTES("signal_vert"));
    modalEcowattVert.show();
  } else if (document.querySelector("lnc-card-meteo > .active").classList.contains("orange") || document.querySelector('lnc-card-meteo > .active').classList.contains('rouge')) {
    if (isFeatureFlippingEcowattOn) {
      const lncWebCardMeteoSelected = getSelectedInfoDay()
      const element = document.getElementById('tuile')
      const labelJourElement = document.createElement('p')
      labelJourElement.classList.add('lnc_text_2')
      labelJourElement.classList.add('bold')
      labelJourElement.setAttribute('id', 'label_jour')
      labelJourElement.innerHTML = 'Jour'

      element.innerHTML = ''
      element.appendChild(labelJourElement)
      element.appendChild(lncWebCardMeteoSelected)
      modalEcowattRougeOrangeFeatureFlippingOn.show()
    } else {
      modalEcowattRougeOrangeFeatureFlippingOff.show()
    }
    if (document.querySelector('lnc-card-meteo > .active').classList.contains('orange')){
      TagCommanderManager.tagClick(PASSAGE_DES_HIVERS_TELECHARGER_LES_COMPTEURS_IMPACTES('signal_orange'))
    } else {
      TagCommanderManager.tagClick(PASSAGE_DES_HIVERS_TELECHARGER_LES_COMPTEURS_IMPACTES('signal_rouge'))
    }
  }
}

function getSelectedInfoDay () {
  let message
  const options = { weekday: 'long', day: '2-digit', month: 'long' }
  const date = new Date(infoDay.date)
  const dateLabel = date.toLocaleDateString('fr-FR', options)
  const formattedDate = dateLabel.charAt(0).toUpperCase() + dateLabel.slice(1)

  if (infoDay.color === ColorEcoWatt.ORANGE) {
    message = MessageEcoWatt.ORANGE
  } else if (infoDay.color === ColorEcoWatt.ROUGE) {
    message = MessageEcoWatt.ROUGE
  }
  return Object.assign(document.createElement('lnc-web-card-meteo'), {
    id: 'lnc-web-card-meteo',
    date: formattedDate,
    message: message,
    color: infoDay.color
  })
}

//#endregion

var infoDay= {}

function getInfoDaysEcowatt () {
  mesEntreprisesService
    .getInfoDaysEcowatt()
    .then((result) => {
      if (result.length > 0) {
        document.getElementById("serviceIndisponibleMeteo").hidden = true;
        const infoDaysEcowatt = result;
        var warningSignal = false;
        // S'il n'y a pas de valeur dans le store, alors on initialise la valeur de la date séléctionnée de la première case (sélectionnée par défaut)
        if (
          !StoreManager.bandeauMeteoDateSelectionneeFormatee
        ) {
          StoreManager.bandeauMeteoDateSelectionneeFormatee = new Date(infoDaysEcowatt[0].date)
            .toISOString()
            .slice(0, 10);
        }
        infoDaysEcowatt.forEach((the_infoDaysEcowatt) => {
          the_infoDaysEcowatt.isSelected = StoreManager.bandeauMeteoDateSelectionneeFormatee === the_infoDaysEcowatt.date;
          the_infoDaysEcowatt.message = MessageEcoWatt[the_infoDaysEcowatt.color];
          if (the_infoDaysEcowatt.color === ColorEcoWatt.ROUGE || the_infoDaysEcowatt.color === ColorEcoWatt.ORANGE) {
            warningSignal = true;
          }
        });
        if (!warningSignal) {
          document.getElementById("encart-download-hidden-error").hidden = true;
          document.getElementById("ecowatt").classList.remove("ecowatt-with-buttons");
        } else {
          document.getElementById("ecowatt").classList.add("ecowatt-with-buttons");
        }

        const lncWebCardsMeteo = Object.assign(document.createElement("lnc-web-cards-meteo"), {
          id: "lnc-web-cards-meteo-hidden-error",
          data: JSON.stringify(infoDaysEcowatt),
        });

        lncWebCardsMeteo.addEventListener('onCardInfoSend', event => {
          const infoDayEcoWatSelected = JSON.parse(event.detail)
          infoDay = {
            'color': infoDayEcoWatSelected.color,
            'date': infoDayEcoWatSelected.date,
            'fullDate': infoDayEcoWatSelected.fullDate
          };

          if (isFeatureFlippingEcowattOn) {
            let selectedDate = infoDay.date;
            let textToDisplay = getTextToDisplay(selectedDate);
            document.getElementById('modal-ecowatt-rouge-orange-feature-flipping-on').getElementsByClassName('info_day_modal_ecowatt_rouge-orange').item(0).innerHTML = textToDisplay;
          }
        })

        function getTextToDisplay (date) {
          let hourToDisplay
          let textToDisplay
          switch (date) {
            case infoDaysEcowatt[0].date:
              hourToDisplay = MessageDayInfo.DAY_0_HOUR
              textToDisplay = MessageDayInfo.INFO_DAY_0
              break
            case infoDaysEcowatt[1].date:
              hourToDisplay = MessageDayInfo.DAY_1_HOUR
              textToDisplay = MessageDayInfo.INFO_DAY_1_2
              break
            case infoDaysEcowatt[2].date:
              hourToDisplay = MessageDayInfo.DAT_2_HOUR
              textToDisplay = MessageDayInfo.INFO_DAY_1_2
              break
            case infoDaysEcowatt[3].date:
              hourToDisplay = MessageDayInfo.DAY_3_HOUR
              textToDisplay = MessageDayInfo.INFO_DAY_3
              break
            default:
              break
          }
          return textToDisplay + hourToDisplay + '.<br/>' + MessageDayInfo.DESCRIPTION + '<br/>' + MessageDayInfo.MSG_ATTENTION;
        }

        document.getElementById("row-tuile-ecowatt").appendChild(lncWebCardsMeteo);

        if (document.querySelector("lnc-card-meteo")) {
          document.querySelectorAll("lnc-card-meteo").forEach((element, index) => {
            element.setAttribute("id", `lnc-card-meteo-${index}`);
            if (element.className == "cursor-pointer") {
              element.addEventListener("click", function () {
                const dateFormatee = new Date(infoDaysEcowatt[index].date)
                  .toLocaleString("fr-FR", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })
                  .replace(/[/]+/g, "-");

                StoreManager.bandeauMeteoDateSelectionneeFormatee = new Date(infoDaysEcowatt[index].date)
                  .toISOString()
                  .slice(0, 10);

                TagCommanderManager.tagClick(
                  PASSAGE_DES_HIVERS_TUILE(
                    dateFormatee,
                    "signal_" + infoDaysEcowatt[index].color.toLowerCase()
                  )
                );
              });
            }
          });
        }

        document.getElementById("tout-comprendre-sur-les-signaux").addEventListener("click", function (event) {
          TagCommanderManager.tagClick(PASSAGE_DES_HIVERS_TOUT_COMPRENDRE_SUR_LES_SIGNAUX);
        });

        //#endregion
        document.getElementById("row-tuile-ecowatt").appendChild(lncWebCardsMeteo);
      } else {
        throw new Error('Aucune information retournée');
      }
    })
    .catch(() => {
      document.getElementById("serviceIndisponibleMeteo").hidden = false;
      if (document.getElementById("encart-download-hidden-error") != null) {
        document.getElementById("encart-download-hidden-error").hidden = true;
      }
    })
    .finally(() => {
      document.getElementById("encartHiver").hidden = false;
    })
}

customElements.define("modal-premiere-connexion", ModalPremiereConnexion, { extends: "div" });

/**
 * Abonnement au Store Manager pour récupérer les habilitations lorsqu'elles sont prêtes
 */
StoreManager.subscribe(StoreKeys.HABILITATIONS, onHabilitationsReady);

function premiereConnexion(dateAffichageMaxPopup) {
  if (new Date() < dateAffichageMaxPopup) {
    chargement_popin();
    document.getElementById("overlay").style.display = "block";
    ComponentHelper.openModal("#modal-premiere-connexion");
    document.getElementById("annuler").addEventListener("click", annulerModal);
    document.getElementById("valider").addEventListener("click", validerModal);
    document.getElementById("annuler-mobile").addEventListener("click", annulerModal);

    window.addEventListener("click", function (e) {
      if (document.getElementById("modal-container").contains(e.target)) {
        //click a l'interieur de la modal
      } else if (document.getElementById("modal-premiere-connexion").contains(e.target)) {
        //click a l'exterieur de la modal
        closeModal();
      }
    });
  }
}

function closeModal() {
  document.getElementById("overlay").style.display = "none";
  jQuery("#modal-premiere-connexion").modal("hide");
  CookiePortail.set("checkPremierAffichage", true);
}

function annulerModal() {
  onClick_popin_pasMaintenant();
  closeModal();
}

function validerModal() {
  onClick_popin_lancerParcours();
  closeModal();
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function onHabilitationsReady() {
  let datePremierRattachement = new Date();


  StoreManager.habilitations.forEach((hab) => {
    let dateCreation = new Date(hab.dateCreation);
    if (dateCreation < datePremierRattachement) {
      datePremierRattachement = dateCreation;
    }
  });
  let dateAffichageMaxPopup = datePremierRattachement.addDays(30);

  if (!CookiePortail.get("checkPremierAffichage")) {
    premiereConnexion(dateAffichageMaxPopup);
  }

  if (isFeatureFlippingEcowattOn) {
    modalEcowattRougeOrangeFeatureFlippingOn.initialiseSirenDropDownMenu();
  }
}

/**
 * Feature flipping du widget mesures sur la page d'accueil
 */
if (!neufsneufSettings.feature_flipping.afficher_widget_mesures_accueil) {
  document.querySelector("#encart-vos-mesures").style.display = "none";
}

/**
 * Abonnement au clic du bouton du lnc-illustrative-feedback
 */
document
  .querySelector(".encart-accueil #bouton-acceder-vos-compteurs")
  ?.addEventListener("click", redirectToVosCompteurs);

document
  .querySelector(".encart-accueil #bouton-acceder-visualiser-mesures")
  ?.addEventListener("click", redirectToVisualiserMesures);

document.getElementById("bouton-tutoriels")?.addEventListener("click", redirectToTutoriels.bind(this));
document.getElementById("bouton-aide-et-contact")?.addEventListener("click", redirectToAideEtContact.bind(this));

if (document.getElementById("img-parcours")) {
  document.getElementById("img-parcours").onclick = function () {
    onClickParcours();
  };
}

//TagCommander
function onClick_popin_lancerParcours() {
  TagCommanderManager.tagClick(CLICK_POPIN_PREMIERE_CONNEXION_LANCER_PARCOURS_GUIDE);
  TagCommanderManager.tagPage(PARCOURS_GUIDE);
  startParcours();
}
function onClick_popin_pasMaintenant() {
  TagCommanderManager.tagClick(CLICK_POPIN_PREMIERE_CONNEXION_PAS_MAINTENANT);
}
function chargement_popin() {
  TagCommanderManager.tagPage(CHARGEMENT_POPIN_PREMIERE_CONNEXION);
}
function redirectToTutoriels() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_TUTORIELS);
}

function redirectToVosCompteurs() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_VOS_COMPTEURS);
  PageManager.redirectTo(PageTypes.VOS_COMPTEURS);
}

function redirectToVisualiserMesures() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_VOS_MESURES);
  PageManager.redirectTo(PageTypes.VISUALISER_VOS_MESURES);
}

function redirectToAideEtContact() {
  TagCommanderManager.tagClick(CLICK_AIDE_ET_CONTACT);
}

import { TypeParcours } from "../../services/enums";
import { TacheManager } from "../../services/tache-manager";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import {
  ERREUR_TELECHARGEMENT_BILAN,
  ERREUR_TELECHARGEMENT_CONTRAT,
  ERREUR_TELECHARGEMENT_FEUILLET,
  MODAL_CONDITIONS_GENERALES,
} from "../../tag-plan";
import NeufsneufToast from "../common/neufsneuf-toast";
import { modalConditionsGenerales, modalSearchExportSuccess } from "../declare-components";
import MicroApp from "./micro-app-base";

/**
 * Microapp compte client
 */
export default class MesContratsMicroApp extends MicroApp {
  childPymEvents = {
    /**
     * Event pour afficher un toast lors de l'erreur de téléchargement d'un document
     * @param {string} typeParcours le type de parcours de type {@type TypeParcours}.
     */
    erreurTelechargementDocument: function (typeParcours) {
      NeufsneufToast.INSTANCE.toastme("Le téléchargement a échoué, veuillez réessayer plus tard");

      switch (typeParcours) {
        case TypeParcours.CONTRATS:
          TagCommanderManager.tagPage(ERREUR_TELECHARGEMENT_CONTRAT);
          break;
        case TypeParcours.FEUILLET_GESTION:
          TagCommanderManager.tagPage(ERREUR_TELECHARGEMENT_FEUILLET);
          break;
        case TypeParcours.BILAN_CONTINUITE:
          TagCommanderManager.tagPage(ERREUR_TELECHARGEMENT_BILAN);
          break;
      }
    },

    /**
     * Event pour réagir à l'arrivée d'une nouvelle tache
     * @param {string} jsonTache string JSON au format {"id": string, "libelle": string, "statut": {EN_ATTENTE|TERMINE|ECHOUE}, "version": integer, "type": {FICHIER_ZIP}}
     */
    exportZipDocumentsSuccess: function (jsonTache) {
      const tache = JSON.parse(jsonTache);
      TacheManager.ajouter([tache]);
      modalSearchExportSuccess.show();
    },
    /**
     * Event pour ouvrir la modal des conditions générales des contrats et avenants.
     */
    openModalConditionsGenerales: function () {
      TagCommanderManager.tagPage(MODAL_CONDITIONS_GENERALES);
      modalConditionsGenerales.show();
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type MesContratsMicroApp}.
   */
  constructor() {
    super();
  }
}
